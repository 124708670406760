.text-3xl {
    font-size: 30px;
}
.card-style2 {
    background-color: #FFF8F4;
    padding: 1rem;
    border-radius: 10px;
}
.review .card {
    border-left: 1px solid #EBEBEB;
    border-top: none;
    border-right: none;
    border-bottom: none;
    padding-left: 20px;
    border-radius: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    
.review .card {
    border-left: none;
    padding-right: 20px;
}
  }