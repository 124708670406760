.swiper {
    width: 100%;
    height: 551px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 551px;
    object-fit: cover;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #6C6C6C;
    opacity: 1;
}
.swiper-pagination-bullet-active {
    background: #E46422;
}
@media screen and (max-width: 500px) {
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 290px;
    object-fit: fill;
  }
  .swiper {
    width: 100%;
    height: 290px;
  }
}
