.tab_style2 .nav-pills .nav-link.active, .tab_style2 .nav-pills .show>.nav-link {
    color: #E46422;
    background-color: #fff;
    border-bottom: 1px solid #E46422;
}
.tab_style2 .nav-pills .nav-link {
    color: #838383;
    background-color: #fff;
    margin-bottom: -1px;
    border: none;
    border-radius: 0px;
}
.border-bottom {
    border-bottom: 1px solid #C8C8C8;
}

/* -----List Start------ */

.list ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
}
.list ul li {
    border-bottom: 1px solid #C8C8C8;
    padding: 13px 0px;
}


@media only screen and (max-width: 500px) {
    .tab_style2 .nav-pills .nav-link {
        padding: 7px 11px;
        margin-bottom: 10px;
    }
    
}
.proTraHis{
    margin-top:-15px
}