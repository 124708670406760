.fileUpload {
    border: 2px dashed #E46422;
    height: 160px;
    display: flex;
    position: relative;
    background: #FFF9F6;
    border-radius: 25px;
    justify-content: center;
    overflow: hidden;
    align-items: center;
}
.fileUpload #base-input {
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    border: none;
    opacity: 0;
}
/* ---------------- */
.staffbody .customDropdown .dropdown-toggle {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    
}
.staffbody .customDropdown .dropdown-menu.show {
    width: 100%;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    padding: 0px;
}
.staffbody .customDropdown .dropdown-item {
    padding: 10px 12px;
    border-top: 1px solid #EBEBEB;
}
.staffbody .customDropdown .dropdown-toggle::after {
    position: absolute;
    top: 16px;
    right: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%277%27 viewBox=%270 0 12 7%27 fill=%27none%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M0.224502 0.224502C-0.0748342 0.523839 -0.0748339 1.00916 0.224502 1.3085L5.43682 6.52081C5.44354 6.52807 5.45043 6.53523 5.45749 6.54229C5.60726 6.69206 5.80359 6.76689 5.99989 6.76679C6.19619 6.76689 6.39252 6.69206 6.54229 6.54229C6.54934 6.53523 6.55624 6.52807 6.56296 6.52081L11.7753 1.3085C12.0746 1.00916 12.0746 0.523839 11.7753 0.224502C11.4759 -0.0748341 10.9906 -0.0748341 10.6913 0.224502L5.99989 4.9159L1.30849 0.224502C1.00916 -0.0748341 0.523838 -0.0748341 0.224502 0.224502Z%27 fill=%27black%27/%3E%3C/svg%3E");
}
