.vision-content {
    padding: 30px 40px;
    margin-left: -115px;
   
    min-height: 378px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px #ccc;
}

.vision-image img {
    height: 550px;
    border-radius: 15px;
}

/* ------------------- */
.ourmission-content {
    background: #fff;
    margin-right: -115px;
    min-height: 350px;
    padding: 30px 40px;
   
    border-radius: 20px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
}
.ourmission-image img{
    height: 500px;
    width: 100%;
    border-radius: 15px;
    z-index: -1;
}
.omission{
    z-index: 1 !important;
}
.about-para{
    text-align: left !important;
}
.our_promise img {
    width: 100px;
    object-fit: cover;
    height: 100px;
    border-radius: 50px;
}

.our_promise .card {
    --bs-card-bg: transparent;
}
/* -------About Us Start----- */
.about-us-radius{
    border-radius: 20px;
}
/* ---------------- */
.directorImage {
    text-align: center;
    margin-bottom: 20px;
}

.directorImage img {
    width: 100%;
    height: 453px;
}

/* ----------------- */
.bg_heading {
    background-color: #1fc8db;
    background-image: linear-gradient(-140deg, #e5d587 0%, #989792 50%, #956464 75%);
}

@media only screen and (max-width: 1399px) {
    .sameHeight {
        min-height: 425px;
    }

    .our_promise .card h4 {
        font-size: 17px;
    }


}

@media only screen and (max-width: 1199px) {
    .sameHeight {
        min-height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .vision-content {
        padding: 20px 20px;
        margin-left: 0px;
        margin-top: -100px;
        border-radius: 20px;
        width: 90%;
       
    }
    .ovision{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .vision-image img {
        border-radius: 20px;
    }

    .ourmission-content {
        width: 90%;
        margin: 0 auto;
       
        height: auto;
        min-height: 221px;
        margin-top: 20px;
        right: 0;
        margin-top: -100px;
        bottom: 0;
        margin-left: auto;
        overflow: hidden;
        padding: 40px;
        border-radius: 20px;
    }
.missionflex{
    flex-direction: column-reverse;
    display: flex;
}
    .directorImage {
        text-align: center;
        margin-bottom: 20px;
    }

    .directorImage img {
        width: 100%;
    }

}

@media only screen and (max-width: 575px) {
    .ourmission-image img {
        width: 100%;
        border-radius: 20px;
    }
}