table th,
table td {
    padding: 10px 10px;
    white-space: nowrap;
    font-size: 14px;
    min-width: 150px;
}

nav .offcanvas-body .dropdown .profileDropdown {
    border: 1px solid #FF8B50;
    border-radius: 100px;
    color: #e46422;
    padding: 2px 8px 2px 4px;
}
nav .offcanvas-body  .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2714%27 height=%278%27 viewBox=%270 0 14 8%27 fill=%27none%27%3E%3Cpath d=%27M1 1L7 7L13 1%27 stroke=%27%23E46422%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E");
    height: 10px;
    width: 10px;
    object-fit: contain;
    position: relative;
    border: none;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 10px;
    top: 2px;
}