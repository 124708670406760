.lh-our-blog {
    background-color: #fff;
    border-radius: 0px;
}
.lh-our-blog .lh-our-blog-post {
    padding: 24px;
}
.lh-our-blog .lh-our-blog-post .lh-our-blog-post-pb .lh-our-blog-post-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.lh-our-blog .lh-our-blog-heading {
    border-bottom: 1px solid #e3e1e1;
    padding-bottom: 4px;
}
.lh-our-blog .lh-our-blog-post .lh-our-blog-post-pb {
    padding-top: 24px;
}
.lh-our-blog .lh-our-blog-post .lh-our-blog-post-pb .lh-our-blog-post-inner img {
    width: 80px;
    height: 80px;
    border-radius: 15px;
}
.lh-our-blog .lh-our-blog-post .lh-our-blog-post-pb .lh-our-blog-post-inner .lh-our-blog-post-contain {
    width: calc(100% - 80px);
    padding: 0 0 0 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.lh-our-blog .lh-our-blog-post .lh-our-blog-post-pb .lh-our-blog-post-inner .lh-our-blog-post-contain a {
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    color: #000;
    text-decoration: none;
    padding-top: 5px;
}

.post-image-card{
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
.blog .card {
    cursor: pointer;
    background-color: #fbfaf7;
    border-radius: 0px;
    border: none;
}
.blog .card:hover .card-title{
   color: #e46422;
}

.blog .card:hover .post-image-card img.card-img-top {
    -webkit-transform: scale(1.05) rotate(2deg);
    transform: scale(1.05) rotate(2deg);
    object-fit: cover;
    opacity: .8;
}
img.card-img-top{
    transition: all .4s ease;
    width: 100%;
}
/* --------------- */
.list-inline-icon ul li svg {
    width: 14px;
    fill: #e46422;
}
.list-inline-icon ul {
    list-style: none;
    padding-left: 16px;
    margin:20px 0px 0px 0px;
}
.list-inline-icon ul li{
    display: inline-block;
    margin-right: 20px;
}
#formBasicEmail::placeholder{
    font-size: 12px !important;
  }
.blog-tag {
    position: absolute;
    top: auto;
    bottom: 15px;
    left: -6px;
    color: #fff;
}
.blog-tag::before{
    position: absolute;
    content: "";
    border-bottom: solid currentColor;
    border-top: solid transparent;
    border-width: 5px;
    color: #e46422;
    border-width: 5px;
    left: 0%;
    top: -10px;
    border-right: solid currentColor;
    border-left: solid transparent;
}
.blog-single-tag {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2em;
    letter-spacing: 1px;
    text-align: center;
    background-color: #e46422;
    width: auto;
    height: auto;
    border-radius: 0;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    align-items: center;
    
}
.blog-single-tag span {
    margin: 7px 0;
    display: block;
    font-size: 13px;
    line-height: 1;
    width: 100%;
}
.blog-single-tag span strong {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3em;
    letter-spacing: 1px;
    color: #ffffff;
    display: block;
}
/* -------------------- */
.explore .card{
    box-shadow: 0px 0px 4px 0px #ccccccab;
    padding-bottom: 10px;
}
.explore .post-image-card:hover .card-img-top {
    transform: scale(1.1);
}
.innerHeading {
    position: absolute;
    bottom: 0;
    padding: 0rem 1rem;
    color: #fff;
}
.explore .card-title {
    margin-bottom: 15px;
}
.explore .card-text {
    min-height: 112px;
}
/* -------------------- */
.blogBanner .swiper {
    width: 100%;
    height: 100%;
  }
  
  .blogBanner .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .blogContent {
    width: 100%;
    height: auto;
}
.blogBanner .swiper-slide img {
    display: block;
    height: 100%;
    object-fit: contain;
    width: 100%;
}
  .swiper-button-prev, .swiper-button-next {
    padding: 20px;
    max-height: 20px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0px 0px 4px #ccc;
    max-width: 20px;
}
.swiper-button-prev:after, .swiper-button-next:after {
    --swiper-navigation-size: 20px;
}
.swiper-button-prev, .swiper-button-next {
    --swiper-theme-color:#000;
}
a.rounded-5.px-4.blogsection {
    color: #fff;
    background-color: #E46422!important;
    border-color: #E46422!important;
    padding: 12px;
    text-decoration: none;
}
/* --------------- */


.blogsmall{
    color: #E46422;
font-size: 20px;
font-style: italic;
font-weight: 600;
line-height: normal;
}
.bloglist li{
    color: #E46422;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 185.023%; /* 40.705px */
}
.blogpoint li{
    color: #E46422;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 185.023%; /* 40.705px */
}
.blogpoint h4{
    font-weight: 900 !important;
}

.bloglist p{
    color: #000;
}

.blog-comment h4{
    color: #000;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.recent-comments h4{
    color: #000;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.comment-submit{
    border-radius: 50px;
}

.about-author h5{
    color: #000 !important;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.about-author p{
    color: #000 !important;
}

.recent-blog-title{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 20px; /* 125% */
}
.recent-blog-date{
    color: #E46422;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.comment-back{
    font-weight: 900;
    z-index: -1;
    color: #cccccca6;
    font-size: 50px;
}
.comment-name{
    font-weight: 900 !important;
    font-size: 20px;
    text-transform: capitalize;
}
.comment-comment{
    font-size: 14px;
    text-align: left;
    text-transform: capitalize;text-transform: capitalize;
}
.comment-date{
    font-size: 12px;
    color: #00000083;
    font-weight: 900;
}
.comment-box{
    width: auto;
    padding-top: 10px;
border-radius: 10px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 27px -5px, rgba(0, 0, 0, 0.3) 0px 0px 16px -8px;
}


@media(min-width:1000px){
    #formBasicEmail::placeholder{
        font-size: 18px !important;
      }
}

@media(max-width:992px){
  .column-reverse-mobile{
    flex-direction: column-reverse !important
  }
  .blog-img{
    margin-bottom: 20px;
  }
  .blog-img img{
    object-fit: contain;
    height: auto; 
  }
}
@media(max-width:320px){
    #formBasicEmail::placeholder{
        font-size: 8px !important;
      }
}

