.arrow_left{
    padding: 0px 7px;
}
.brFloq {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}
.hbgCLW {
    font-size: 14px;
}
.kqWIVd {
    color: #1C1C1C!important;
}
.filterbtn::after{
    display: none;
}