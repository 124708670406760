body {
  --bs-body-font-family: Montserrat;
  --bs-body-color: #000;
  margin: 0;
  font-family: var(--bs-body-font-family);
  color: var(--bs-body-color);

}

.pull-left {
  float: left;
}

body .verticallist .nav-pills .nav-link.active,
body .verticallist .nav-pills .show>.nav-link {
  background: #fff;
  border: none !important;
}

body .btn.btn-outline-primary:first-child:active {
  --bs-btn-active-border-color: #E46422;
  --bs-btn-active-bg: #E46422;
  --bs-btn-active-color: #fff;
}

body .btn.btn-primary:active {
  background: #E46422;
  border: 1px solid #E46422;
  color: #fff;
}
body .btn-check:checked+.btn, body .btn.active, .btn.show, body .btn:first-child:active, body :not(.btn-check)+.btn:active {
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dee2e6;
}

/* ---------------------- */
body div:where(.swal2-container) button:where(.swal2-styled).swal2-default-outline:focus {
  box-shadow: none;
}

body div:where(.swal2-container) div:where(.swal2-popup) {
  width: 20em;
}

body .tab_style4 .nav-pills .nav-link:focus,
body .tab_style4 .nav-pills .nav-link:hover {
  color: rgb(207, 84, 22);
}

@media only screen and (max-width: 500px) {
  body .px-xs-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  body .px-xs-1 {
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
  }

  body .px-xs-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  body .px-xs-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  body .px-xs-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}