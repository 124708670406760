.subscriptionplan .card-img-top {
    width: 60px;
    border-radius: 12px;
    object-fit: cover;
    border: 1px solid #cccccc52;
    object-position: center;
}
.subscriptionplan .card {
    padding: 25px;
    border-radius: 25px;
    height: 100%;
}
.subscriptionplan .card-body {
    padding: 20px 0px;
}
.subscriptionplan .card h2, .subscriptionplan .card h5 {
    color: #170F49;
}
.subscriptionplan .card h6 {
    color:#170F49;
}
.subscriptionplan .card-body p, .subscriptionplan .card-body span {
    color: #6F6C90;
}
.subscriptionplan .card-body ul {
    list-style: none;
    padding-left: 0px;
}
.subscriptionplan .card-body ul li {
    color: #170F49;
    margin: 15px 0px;
}
.subscriptionplan .card-body ul li img {
    border: 1px solid transparent;
}
.subscriptionplan .btn-primary {
    background-color: #0049A6;
    border-color: #0049A6;
}
.subscriptionplan .btn-primary:hover {
    background-color: #0049A6;
    border-color: #0049A6;
}
/* ------------------------ */
.subscriptionplan .card:hover {
    background-color: #0049A6;
    cursor: pointer;
}
.subscriptionplan .card:hover .btn-primary {
    background-color: #fff;
    border-color: #fff;
    color: #fff;
}
.subscriptionplan .card:hover .btn-primary:hover {
    background-color: #fff;
    border-color: #fff;
}
.subscriptionplan .card:hover .card-body ul li {
    color: #D9DBE9;
}
.subscriptionplan .card:hover h6 {
    color:#fff;
}
.subscriptionplan .card:hover h2, .subscriptionplan .card:hover h5 {
    color: #fff;
}
.subscriptionplan .card:hover .card-body p, .subscriptionplan .card:hover .card-body span {
    color: #D9DBE9;
}
.popular_btn button {
    background: #FFFFFF33;
    border: none;
    border-radius: 10px;
    opacity: 0;
    float: right;
    color: #fff;
    position: absolute;
    padding: 8px 10px;
    right: 20px;
}
.subscriptionplan .card:hover .popular_btn button {
   opacity: 1;
}
.subscriptionplan .card:hover .card-body ul li img {
    border: 1px solid #fff;
    background: #fff;
    border-radius: 20px;
}

.subscriptionplanDetails .card-img-top {
    width: 60px;
    border-radius: 12px;
    object-fit: cover;
    border: 1px solid #cccccc52;
    object-position: center;
}
.subscriptionplanDetails .card {
    padding: 25px;
    border-radius: 25px;
}
.subscriptionplanDetails .card-body {
    padding: 20px 0px;
}
.subscriptionplanDetails .card h2, .subscriptionplanDetails .card h5 {
    color: #170F49;
}
.subscriptionplanDetails .card h6 {
    color:#170F49;
}
.subscriptionplanDetails .card-body p, .subscriptionplanDetails .card-body span {
    color: #6F6C90;
}
.subscriptionplanDetails .card-body ul {
    list-style: none;
    padding-left: 0px;
}
.subscriptionplanDetails .card-body ul li {
    color: #170F49;
    margin: 15px 0px;
}
.subscriptionplanDetails .card-body ul li img {
    border: 1px solid transparent;
}
.subscriptionplanDetails .btn-primary {
    background-color: #0049A6;
    border-color: #0049A6;
}
/* ---------------- */
.back-btn {
  text-align: left;
}
.back-btn button {
    border: 1px solid #000000!important;
    padding: 7px 8px;
    max-height: 32px;
    max-width: 32px;
    line-height: 0;
}
.back-btn button img {
    height: 15px;
    width: 15px;
}

@media only screen and (min-width: 500px){
.paypal-button-container {
    max-width: 100%!important;
}}