.goog-te-banner-frame {
  display: none !important;
}

body {
  top: 0px !important;
}

.goog-te-gadget {
  font-size: 0 !important;
}

.goog-te-gadget span {
  display: none;
}

.goog-te-gadget img {
  display: none !important;
}
/* Container styling */
.translate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  background: transparent;
  border-radius: 8px;
}

/* Language dropdown styling */
#google_translate_element select {
  padding: 8px 40px 8px 17px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-size: 22px;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  background-color: white;
  outline: none;
  height: 45px;
  font-size: 14px;
  border-radius: 2em;
  cursor: pointer;
}
.skiptranslate iframe {
  display: none!important;
}

/* Hover effect */
#google_translate_element select:hover {
  border-color: #e46422;
}