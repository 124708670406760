.rangeslider .container {
    position: relative;
  }
  .rangeslider .slider {
    position: relative;
    width: 100%;
  }
  
  .rangeslider .slider__track,
  .rangeslider .slider__range,
  .rangeslider .slider__left-value,
  .rangeslider .slider__right-value {
    position: absolute;
  }
  
  .rangeslider .slider__track,
  .rangeslider .slider__range {
    border-radius: 3px;
    height: 5px;
  }
  
  .rangeslider .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }
  
  .rangeslider .slider__range {
    background-color: #e46422;
    z-index: 2;
}
  
  .rangeslider .slider__left-value,
  .rangeslider .slider__right-value {
    color: #000;
    font-size: 12px;
    margin-top: 12px;
    font-weight: 800;
  }
  
  .rangeslider .slider__left-value {
    left: -10px;
  }
  
  .rangeslider .slider__right-value {
    right: -10px;
  }
  
  /* / Removing the default appearance / */
  .rangeslider .thumb,
  .rangeslider .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .rangeslider .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    right: 0;
    outline: none;
}
  
  .rangeslider .thumb--left {
    z-index: 3;
  }
  
  .rangeslider .thumb--right {
    z-index: 4;
  }
  
  /* / For Chrome browsers / */
  .rangeslider .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* / For Firefox browsers / */
  .rangeslider .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  