.pr-0 {
    padding-right: 0rem;
}

.two_month_date .rmdp-input {
    border: 1px solid #c0c4d6;
    /* border-radius: 5px; */
    height: 40px;
    opacity: 0;
    top: 0;
    border-radius: 20px;
    overflow: hidden;
    left: 0;
    right: 0;
    margin: 1px 0;
    position: absolute;
    padding: 2px 5px;
}

.two_month_date .rmdp-arrow-container:hover .rmdp-arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    cursor: pointer;

}

/* .two_month_date .rmdp-arrow-container.disabled .rmdp-arrow, .rmdp-arrow-container.disabled:hover .rmdp-arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;

} */
/* ------------------ */
.amenitiesBox {
    position: relative;
}

.amenitiesBox::before {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 70px;
    background: #dee2e6;
}

/* --------------- */
.socialicon svg {
    font-size: 26px;
}

.propertydetailImg img {
    height: 543px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 40px;
    object-fit: cover;
    object-fit: fill;
}

.propertydetail .collapsImg ul {
    list-style: none;
    padding-left: 0px;
}

.propertydetail .collapsImg li {
    display: inline-block;
    width: 50%;
    padding: 5px 5px;
}

.propertydetail .collapsImg img {
    height: 215px;
    /* object-fit: cover; */
    /* margin: 1px; */
    width: 100%;
    border: 1px solid #FFF;
}

.nft_pic.nft_multi a {
    position: relative;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    width: calc(50% - 10px);
    float: left;
    overflow: hidden;
}

.nft_pic.nft_multi a:nth-child(1) {
    margin: 0 5px 5px 0;
    height: 267px;
}

.nft_pic.nft_multi a:nth-child(2) {
    margin: 0 0 5px 5px;
    height: 267px;
}

.nft_pic.nft_multi a:nth-child(3) {
    margin: 5px 5px 0 0;
    height: 267px;
}

.nft_pic.nft_multi a:nth-child(4) {
    margin: 5px 0 0 5px;
    height: 267px;
}


.nft_pic.nft_multi6 a {
    position: relative;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    width: calc(50% - 10px);
    float: left;
    overflow: hidden;
}

.nft_pic.nft_multi6 a:nth-child(1) {
    margin: 0 5px 5px 0;
    height: 267px;
}

.nft_pic.nft_multi6 a:nth-child(2) {
    margin: 0 0 5px 5px;
    height: 267px;
}

.nft_pic.nft_multi6 a:nth-child(3) {
    margin: 5px 5px 0 0;
    height: 300px;
    width: 100%;
}

.nft_pic.nft_multi6 a:nth-child(4) {
    margin: 5px 0 0 5px;
    height: 267px;
}

/* --------Rating line----------- */
.rating_line {
    position: relative;
}

.rating_star {
    margin-top: -4px;
}

.reviewImg {
    width: 64px;
    height: 63px;
    border-radius: 50px;
    object-fit: cover;
}

.viewmore:hover {
    background: #E46421 !important;
    border-color: #e46422 !important;
}

/* ----------------- */
.two_month_date .rmdp-range {
    background-color: #FFF7F2;
    box-shadow: 0 0 3px #8798ad00;
    color: #000;
}

.two_month_date .rmdp-day span {
    border-radius: 2px 2px 2px 2px;
}

.two_month_date .rmdp-range-hover.start:not(.force),
.two_month_date .rmdp-range.start:not(.force) {
    border-bottom-left-radius: 8px;
    background-color: #E46422;
    color: #fff;
    border-top-left-radius: 8px;
}

.two_month_date .rmdp-day:not(.rmdp-disabled, .two_month_date .rmdp-day-hidden) span:hover {
    background-color: #e46422;
    color: #fff;
}

.two_month_date .rmdp-range-hover.end:not(.force),
.rmdp-range.end:not(.force) {
    border-bottom-right-radius: 8px;
    background-color: #E46422;
    color: #fff;
    border-top-right-radius: 8px;
}

.two_month_date .rmdp-day.rmdp-today span {
    background-color: transparent;
    color: inherit;
}

.two_month_date .rmdp-week-day {
    color: #272727;
    cursor: default;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
}

.two_month_date .rmdp-arrow {
    border: solid #e46422;
    border-width: 0 2px 2px 0;
    display: inline-block;
    height: 8px;
    margin-top: 5px;
    padding: 2px;
    width: 8px;
}

.two_month_date .rmdp-arrow-container:hover {
    background-color: #e46422;
    box-shadow: 0 0 3px #e4642200;
}

.rmdp-arrow-container {
    height: 19px;
    justify-content: center;
    margin: 1px 5px;
    padding: 0px;
    text-align: center;
    display: flex;
    width: 19px;
}

@media screen and (max-width: 480px) {
    .rmdp-day-picker {
        display: block;
    }

    .propertydetailImg img {
        height: 310px;
        margin-bottom: 10px;
    }

    body .nft_pic.nft_multi img {
        object-fit: fill;
        height: 150px;
    }

    .nft_pic.nft_multi a:nth-child(1) {
        height: 150px;
    }

    .nft_pic.nft_multi a:nth-child(2) {
        height: 150px;
    }

    .nft_pic.nft_multi a:nth-child(3) {
        height: 150px;
    }

    .nft_pic.nft_multi a:nth-child(4) {
        height: 150px;
    }

    .nft_pic.nft_multi a {
        width: calc(50% - 5px);
    }


    .propertydetailImg img {
        height: 310px;
        margin-bottom: 10px;
    }

    .nft_pic.nft_multi6 img {
        object-fit: fill;
        height: 150px;
    }

    .nft_pic.nft_multi6 a:nth-child(1) {
        height: 150px;
    }

    .nft_pic.nft_multi6 a:nth-child(2) {
        height: 150px;
    }

    .nft_pic.nft_multi6 a:nth-child(3) {
        height: 150px;
    }

    .nft_pic.nft_multi6 a:nth-child(4) {
        height: 150px;
    }

    .nft_pic.nft_multi6 a {
        width: calc(50% - 5px);
    }


}