.footer-block ul {
    list-style: none;
    padding-left: 0px;
}
.footer-block ul li {
    font-size: 15px;
    margin-bottom: 5px;
}
.footer-block ul li a {
    text-decoration: none;
    color: #000;
}

.footer-block ul li a:hover {
    color: #e46422;
}


/* -----------Social Icon list----------------- */
.social-icon ul {
    list-style: none;
    padding-left: 0px;
}
.social-icon ul li {
    display: inline-block;
    background: #E46422;
    margin-right: 12px;
    height: 30px;
    width: 30px;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
}
.social-icon ul li img {
    width: 15px;
    height: 15px;
    object-fit: contain;
}

.appstore{
    width: 100%;
}
.appstore a img {
    width: 100px;
    height:30px;
}