/* Navbar.css */
.text-primary{
  color: #E46422!important;
}
.nav-link {
  color: #000;
  margin-right: 10px;
  font-size: 16px;
}
.msgnumber {
  position: absolute;
  top: 4px;
  right: 8px;
  background: #e46422;
  border-radius: 30px;
  height: 22px;
  color: #fff;
  padding: 3px;
  width: 22px;
  font-size: 11px;
}
.inboxmsg {
  background: #e46422;
  border-radius: 50%;
  width: 20px;
  max-width: 16px;
  max-height: 16px;
  display: inline-block;
  position: relative;
  margin-left: 1px;
  height: 20px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  padding: 1px;
  top: -6px;
}
.msg {
  background: #e46422;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  font-size: 11px;
  padding: 2px 3px;
}
.dropdown-item.active, .dropdown-item:active {

  background-color: #e46422;
}

/* body .dropdown-item {
  white-space:unset;
} */

a.active.nav-link {
  border-bottom: 2px solid #e46422;
  font-weight: 600;
}
.dropdown-menu a:hover {
  background-color: #e46422;
  color: #fff;
}
.dropdown-menu a:hover span{
  background-color: #fff;
  color: #000;
}