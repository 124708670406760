
/* vietnamese */
@font-face {
    font-family: 'Archivo';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/archivo/v19/k3kBo8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYCx-muLRm.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Archivo';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/archivo/v19/k3kBo8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYCx6muLRm.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Archivo';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/archivo/v19/k3kBo8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYCxCmuA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/archivo/v19/k3kPo8UDI-1M0wlSV9XAw6lQkqWY8Q82sLySOxK-vA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/archivo/v19/k3kPo8UDI-1M0wlSV9XAw6lQkqWY8Q82sLyTOxK-vA.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/archivo/v19/k3kPo8UDI-1M0wlSV9XAw6lQkqWY8Q82sLydOxI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
/* ----------------- */
.staffbody{
        --bs-body-font-family: Archivo;
        --bs-body-color: #000;
        margin: 0;
        font-family: var(--bs-body-font-family);
        color: var(--bs-body-color);
}
.staffbody .text-light-grey {
    color: #6E6E6E;
}

.staffbody label {
  font-size: 16px;
}
.staffbody .text-lighter-grey{
  color: #C1C1C1;
}
.font-italic {
  font-style: italic;
}
.text-green {
  color: #2FAA10;
}
/* -----Header start--------- */
.staffbody .navbar-toggler-icon {
  width: 1em;
  height: 1rem;
  --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='20' viewBox='0 0 26 20' fill='none'%3E%3Cpath d='M14.625 19.9998H1.625C0.65 19.9998 0 19.3332 0 18.3332C0 17.3332 0.65 16.6665 1.625 16.6665H14.625C15.6 16.6665 16.25 17.3332 16.25 18.3332C16.25 19.3332 15.6 19.9998 14.625 19.9998Z' fill='%23E46422'/%3E%3Cpath d='M24.375 11.6668H1.625C0.65 11.6668 0 11.0002 0 10.0002C0 9.00016 0.65 8.3335 1.625 8.3335H24.375C25.35 8.3335 26 9.00016 26 10.0002C26 11.0002 25.35 11.6668 24.375 11.6668Z' fill='%23E46422'/%3E%3Cpath d='M24.375 3.33333H11.375C10.4 3.33333 9.75 2.66667 9.75 1.66667C9.75 0.666667 10.4 0 11.375 0H24.375C25.35 0 26 0.666667 26 1.66667C26 2.66667 25.35 3.33333 24.375 3.33333Z' fill='%23E46422'/%3E%3C/svg%3E);
}
.staffbody .navbar-toggler {
  --bs-navbar-toggler-border-color:transparent;
  box-shadow: none;
  height: 23px;
}
/* -----Table start---------- */
.staffbody .rdt_TableHead {
  margin-bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
}
.staffbody .rdt_TableHead .rdt_TableHeadRow{
  border: none;
}
.staffbody .rdt_TableRow {
  border: 1px solid #EBEBEB;
  margin-bottom: 20px;
  border-radius: 15px;
}
.staffbody .rdt_TableRow:last-child {
  margin-bottom: 0px;
}
.staffdashboard table tbody tr:last-child {
  border: 1px solid #C8C8C8;
}

.recentImg {
  width: 60px;
  height: 46px;
}
.recentImg img{
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.overlay-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.30);
  z-index: 1;
  display: flex;
  backdrop-filter: blur(7.5px);
  right: 0;
  text-align: center;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.staff_upcoming_booking .upcomingList {
  height: 455px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* -------------- */
.staffbody .card .card-header {
    background-color: #fff!important;
    padding: 4px 0px 20px 0px;
    margin-bottom: 20px;
    border-color: #EBEBEB;
}
.staffbody .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23E46422' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    height: 10px;
    width: 10px;
    object-fit: contain;
    position: relative;
    border: none;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 10px;
    top: 2px;
}
.staffbody .makebtn .dropdown-toggle::after{
   margin-left: 0px;
}
.staffbody .dropdown-toggle.btn-outline-primary:hover::after {
filter: brightness(0) invert(1);
}
.profileD button {
    padding: 5px 16px 5px 5px!important;
}
.staffbody .profileD {
    background: #FFF;
}
.staffbody .profileD button{
    border-radius: 32.5px;
    border: 1px solid #FF8B50!important;
    color: #e46422!important;}
/* ----------------- */
.bg-pink {
  background-color: #F7F7F7;
}

.card-light-orange{
background: #FFF8F4;
}
.staffdashboard .graph .canvasjs-chart-credit {
  display: none;
}

.totalbooking {
  border-left: 2px solid #EBEBEB;
  height: 180px;
  display: flex;
  align-items: center;
}
.totalbooking .tbox {
  border-radius: 10px;
}

/* ------------Finance Dashboard Start------------- */
.tab_style .tablistfinance .nav-pills .nav-link.active, .tab_style .nav-pills .nav-link {
  padding: 8px 30px;
}
.jvsMYy {
  color: #000;
}
.jmeUcu svg {
  display: none!important;
}
.iRHDKS{
  outline: none;
}
.jNfPqw:disabled {
  cursor: unset;
  color: #000;
  fill: #000;
}

/* ------------------------- */

@media only screen and (max-width: 767px) {
 
  .profileMobile {
    flex-direction: row-reverse;
  }
  .profileMobile .profileContent {
    width: 100%;
  }
    .mobileBtn{
        width: 100%;
  }
}
@media only screen and (max-width: 320px) {
.tab_style .tablistfinance .nav-pills .nav-link.active, .tab_style .nav-pills .nav-link {
  padding: 8px 15px;
}}