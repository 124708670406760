
.staffbody .testimonialSlider .card {
    border-radius: 15px;
    border: 1px dashed #000;
    background: #FFF9F6;
}
.staffbody .testimonialSlider .card::before {
    content: "";
    background: #E46422;
    position: absolute;
    left: -2px;
    top: 19px;
    height: 42px;
    width: 3px;
}

.x-small {
    font-size: 14px!important;
}
.xx-small {
    font-size: 10px!important;
}
/* --------- */
.staffbody .swiper {
    width: 100%;
    height: 100%;
  }
  
  .staffbody .swiper-slide {
    text-align: left;
    font-size: 18px;
    cursor: pointer;
    background: #fff;
    width: 320px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .staffbody .swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* .staffbody .swiper-slide {
    width: 25%;
  } */
  
  /* .staffbody .swiper-slide:nth-child(2n) {
    width: 60%!important;
  } */
  
  .staffbody .swiper-slide:nth-child(4n) {

  }
  
  @media only screen and (max-width: 330px) {
  .staffbody .swiper-slide {
    width: 100% !important;
}}