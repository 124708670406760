.scroll-container {
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  cursor: grab;
  margin: 0 40px;
}
  
  .scroll-container:active {
    cursor: grabbing; /* Change cursor while dragging */
  }
  
  .scroll-button {
    border-radius: 32.5px;
    border: 1.5px solid #E46422;
    background: rgba(228, 100, 34, 0.00);
    display: inline-block;
    padding: 10px 30px;
    color: #E46422;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    white-space: nowrap;
}
.left-arrow {
  position: absolute;
  left: 0px;
  top: 10px;
  line-height: 1;
  padding: 7px;
}
.right-arrow {
  position: absolute;
  right: 0px;
  top: 10px;
  line-height: 1;
  padding: 7px;
}
.scroll-button.active {
    background-color: #E46422;
    color: #fff;
  } 
  .scroll-button.active a{
    color: #fff;
  } 
  .scroll-button a{
    color: #E46422;
  }
  body .scroll-button:hover a {
    color: #fff;
}
body .btn-primary a{
  color: #fff;
}
  .scroll-button:hover {
    background-color: #E46422;
    color: #fff;
  }
  
  .scroll-controls {
    text-align: center;
    margin-top: 10px;
  }
  
  .scroll-controls button {
    padding: 10px;
    margin: 0 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .scroll-controls button:hover {
    background-color: #0056b3;
  }

  @media only screen and (max-width: 991px) {
    .scroll-container {
        overflow-x: auto;
    }
    /* width */
    .scroll-container ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
.scroll-container ::-webkit-scrollbar-track {
  background:transparent; 
}
 
/* Handle */
.scroll-container ::-webkit-scrollbar-thumb {
  background:#fff; 
}

/* Handle on hover */
.scroll-container ::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}
  }