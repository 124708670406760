/* PageNotFound.css */
.page-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #8a9bb3;
    color: #333;
  }
  
