.tab_style .nav-pills .nav-link.active, .tab_style .nav-pills .show>.nav-link {
    color:  #fff;
    background-color: #f25e2e;
    border: 1px solid #f25e2e;
}
.tab_style .nav-pills .nav-link {
    color: #838383;
    background-color: #fff;
    margin-bottom: 12px;
    border: 1px solid #838383;
    border-radius: 25px;
}
@media screen and (max-width: 425px) {
.listing .tab_style .nav-item {
    width: 50%;
    text-align: center;
}}

.trxTable th{
    background-color: #838383;
    color: #fff;
  }