
.navbar {
  border-bottom: 1px solid #EBEBEB;
  padding: 12px 0px;
}
.navbar-nav {
  --bs-nav-link-padding-y: 0.75rem;
}


.logo img {
  width: 150px; 
  object-fit: contain;
  height: 50px; 
}
@media only screen and (max-width: 1199px) {
  .offcanvas-body .navbar-nav .dropdown button {
    padding-left: 0px;
  }
}
