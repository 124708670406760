.accordion-button:focus {
    --bs-accordion-btn-focus-border-color:#f8f8f8;
    --bs-accordion-btn-focus-box-shadow:transparent;
}
.accordion-button:not(.collapsed) {
    --bs-accordion-active-bg:#f8f8f8;
    border-bottom: 1px solid #dee2e6;
}
.accordion-button:not(.collapsed)::after {
    --bs-accordion-btn-active-icon:url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e46422'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);
}
.accordion-button::after {
    --bs-accordion-btn-icon:url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e46422'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);
}
.accordion-button:hover {
    background-color: #f8f8f8;
}
