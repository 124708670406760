.pl-1 {
    padding-left: 1rem;
}
.pl-2 {
    padding-left: 2rem;
}
.pl-3 {
    padding-left: 3rem;
}
.pl-4 {
    padding-left: 4rem;
}

.text-blue{
    color: #0049A6;
}
.stepforms .css-ahj2mt-MuiTypography-root, .stepforms .css-9l3uo3{
    font-family: Montserrat;

}
.stepforms .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #0049A6;
}
.stepforms .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {

    color: #0049A64D;
}

.stepforms .css-z7uhs0-MuiStepConnector-line {
    border-color: #0049A64D;
}
.stepforms .css-vqmohf-MuiButtonBase-root-MuiRadio-root, .stepforms .css-1a5icme {
    color: #0049A666;
}
.stepforms .css-v4u5dn-MuiInputBase-root-MuiInput-root, .stepforms .css-1w1rijm-MuiButtonBase-root-MuiButton-root, .stepforms .css-mqt2s5, .stepforms .css-5bcq5j, .stepforms .css-1e6y48t-MuiButtonBase-root-MuiButton-root, .stepforms .css-1atslg0-MuiTypography-root, .stepforms .css-1ujsas3, .stepforms .css-94lbm9 {
    font-family: Montserrat;
}
.stepforms .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root, .stepforms .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root, .stepforms .css-zrqpq1, .stepforms .css-1o3proq-MuiButtonBase-root-MuiButton-root, .stepforms .css-a1sg1c-MuiButtonBase-root-MuiButton-root, .stepforms .css-94lbm9, .stepforms .css-1oa4sff, .stepforms .css-cufvuv {
    font-family: Montserrat;
    
}


.stepforms .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label,.stepforms .css-9l3uo3 {
    font-size: 16px;
}
.stepforms .css-1o3proq-MuiButtonBase-root-MuiButton-root.Mui-disabled, .stepforms .css-94lbm9.Mui-disabled {
    color: #000;
    border: 1px solid #000;
}
.stepforms .css-1e6y48t-MuiButtonBase-root-MuiButton-root, .stepforms .css-1ujsas3 {

    color: #000;
    border: 1px solid #000;
}
.stepforms .css-a1sg1c-MuiButtonBase-root-MuiButton-root, .stepforms .css-1oa4sff{
    border: 1px solid #000;
}
.stepforms .css-1o3proq-MuiButtonBase-root-MuiButton-root, .stepforms .css-94lbm9{
    border: 1px solid #000;
}
.stepforms .css-1hbvpl3-MuiSvgIcon-root, .stepforms .css-q8lw68 {
    width: 16px;
    height: 16px;
}

.stepforms .css-11zohuh-MuiSvgIcon-root,.stepforms .css-1c4tzn, .stepforms .css-1u5ei5s {
    width: 16px;
    height: 16px;
}
.stepforms .css-1hhw7if-MuiSvgIcon-root {

    width: 16px;
    height: 16px;

}
/* -------------------- */
.stepforms .addvideo {
    border: 2px dashed #ccc;
    width: 190px;
    /* display: flex; */
    text-align: center;
    overflow: hidden;
    padding: 0px  0px;
    height: 150px;
    object-fit: contain;
}
.stepforms .addvideo video {
    width: 100%;
    height: 120px;
    object-fit: contain;

}

.stepforms .upload_documents{
    border: 1px solid #838383;
    border-radius: 5px;
}
.stepforms .btn-number {
    border: 1px solid #0049A666;
    height: 30px;
    width: 30px;
    margin-top: 7px;
}
.stepforms .input-number {
    text-align: center;
}
.stepforms .form-check-input {
    border-radius: 0px;
    border: 1px solid #9ebadd;
    background-color: #fff;
}
.stepforms .form-check-input:checked {
    background-color: #1976d2;
    border-color: #1976d2;
}
.addvideo svg {
    width: 33px;
    height: 33px;
    stroke: #ccc;
}
/* ----------Add Photos Css-------------- */
.addphotos ul{
    list-style: none;
    padding-left: 0px;
}
.addphotos {
    display: inline-flex;
    cursor: pointer;
}
.addphotos .p-list {
    border: 2px dashed #ccc;
    width: 190px;
    text-align: center;
    display: inline-block;
    position: relative;
    padding: 44px  0px;
    margin-bottom: 20px;
    height: 138px;

    object-fit: contain;
}
.addphotos .p-list svg {
    width: 33px;
    height: 33px;
    stroke: #ccc;
}
.p-list input[type="file"] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}
/* ------------- */
.btn.remove {
    border-radius: 20px;
    height: 30px;
    width: 30px;
    font-size: 13px;
    padding: 3px;
    position: absolute;
    right: -12px;
    top: -12px;
}
.addphotos .p-list .preview {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 134px;
    object-fit: contain;
}
body .css-iwcgw7-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Montserrat';
   
}
body .css-1717ajk-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Montserrat';
}
