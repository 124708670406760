.circleIcon {
    border: 1px solid #F3F3F3;
    height: 65px;
    width: 65px;
    text-align: center;
    vertical-align: middle;
    border-radius: 100px;
    padding: 17px;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    /* --bs-btn-active-color:#000; */
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
}

/* .dropdown-toggle.btn-check:checked+.btn, .dropdown-toggle.btn.active, .dropdown-toggle.btn.show, .dropdown-toggle.btn:first-child:active, :not(.btn-check)+.btn:active {
    --bs-btn-active-color:;
    --bs-btn-active-bg:;
    --bs-btn-active-border-color:;
} */
/* ------Dashboard Css---------- */
table tr {
    border-bottom: 1px solid #C8C8C8;
}

table th,
table td {
    padding: 10px 10px;
    white-space: nowrap;
    font-size: 14px;
}
.listing .rdt_TableCell{
    cursor: pointer;
  }
table td {
    color: #838383;
}
/* 
table tbody tr:last-child {
    border: none;
} */
.dash{
    font-size: 14px;
}
/* ------------------- */

.boxblue .card h2 {
    color: #0049A6;
}

.boxblue .card {
    min-height: 135px;
    background-color: #e5edf6;
    border: 2px solid #0049a657;
    transition: all .5s ease;
    display: flex;
    justify-content: center;
    align-items:stretch;
}

.boxblue .card:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 5px rgb(0 0 0 / 10%);
}

.boxblue .card::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 0;
    height: 100%;
    background: #edf4fb;
    transition: all .5s ease;
    z-index: -1;
}

.boxblue .card:hover::after {
    left: 0;
    width: 100%;
}

/* ---------------- */
.upcoming_booking .no_data_found span {
    min-height: 523px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upcoming_booking ul {
    list-style: none;
    padding-left: 0px;
    margin-top: 20px;
    margin-bottom: 0px;
}

.upcoming_booking ul li {
    margin-bottom: 10px;
}

.upcoming_booking ul li h6 {
    font-size: 14px;
}

.upcoming_booking .card {
    min-height: 582px;
}

/* ----------Reviews Start ------------- */
.review .card {
    min-height: 80px;
}

.review svg {
    fill: #FFB800;
    margin-right: 5px;
}

.review table th,
.review table td {
    padding: 5px 0px;
    min-width: 100%;
}

/* .sales-report table th, .sales-report table td {
    padding: 5px 0px;
    min-width: inherit;
} */

/* --------------- */
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    border-bottom: 2px solid #e46422;
    font-weight: 600;
}

.navbar-nav .nav-link {
    border-bottom: 2px solid transparent;
}