.seologo {
    margin-bottom: 0px;
    padding-top: 25px;
    position: absolute;
}
.seoheader .emailContact{
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.seoheader{
    position: absolute;
    padding: 33px 0px;
    width: 100%;
}
.emailContact ul{
    list-style: none;
    margin-bottom: 25px;
    padding-left: 0px;
}
.emailContact ul li {
    display: inline-block;
    text-align: left;
    margin-bottom: 0px;
    margin-right: 15px;
}
.emailContact ul li span svg{
  height: 1em;
  width: 1em;
  object-fit: contain;
}

.seopage .banner-area{
    min-height: 580px;
    padding: 80px 0px;
    background-size: 100% 100%;
    text-shadow: 3px 0px -1px #ffffff;
}

.whychooseus .card {
    min-height: 194px;
}
.about-content {
    padding-right: 90px;
    /* text-align: justify; */
}
.seopage .section-title-line {
    background: #e46446;
    margin-left: 0;
    width: 80px;
    margin-bottom: 15px;
}
.innerbanner {
    position: absolute;
    top: 0;
    z-index: -1;
    right: 0;
    bottom: 0;
    left: 0;
}
.innerbanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.banner-content {
    padding: 25px 15px 25px 0px;
    /* background: #07070738; */
    min-height: 273px;
    width: 80%;
    /* border-left: 5px solid #ffffff; */
}
.sameheight img {
    height: 572px;
    object-fit: contain;
    border-radius: 20px;
}
.sameheight {
    min-height: 532px;
    border-radius: 30px;
    border: 4px solid #fff;
}
.propertyImage{
    position: relative;
}
.bg-light-grey{
    background-color:#f3f3f3;
}
.propertyImage .img2 {
    background: rgba(242,246,247,1);
    border-radius: 6px;
    position: absolute;
    width: 57%;
    padding: 8px;
    top: -82px;
    right: 0;
}
.propertyImage .img1 {
    background: rgba(242,246,247,1);
    border-radius: 6px;
    padding: 8px;
    top: -18px;
    position: relative;
    width: 85%;
    z-index: 1;
}
.propertyImage .img3 {
    background: rgba(242,246,247,1);
    border-radius: 6px;
    padding: 8px;
    top: 218px;
    position: absolute;
    right: 24px;
    width: 35%;
    z-index: 1;
}
.box-height {
    min-height: 260px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.Aboutus {
    padding: 120px 0px 26px 0px;
}
/* ---------Register Modal--------- */
.register-modal .modal-content {
    border-radius: 26px;
}
.register-modal .modal-body{
    margin-top: -50px;
    padding: 30px;
}
.register-modal .modal-header .btn-close {
    position: relative;
    top: 5px;
    right: 4px;
    z-index: 11;
    font-size: 15px;
}
.form_select{
    border-radius: 0px;

}
.form_select:focus {
    border-color: #cf5416;
    outline: 0;
    box-shadow: none;
}
/* ----------footer------------ */
.call_to_auction {
    position: fixed;
    bottom: 98px;
    right: 20px;
    z-index: 11;
}
.seofooter {
    background: #213343;
}
.seofooter .social-icon ul li {
    display: inline-block;
    background: #E46422;
    margin-right: 12px;
    height: 35px;
    width: 35px;
    border-radius: 30px;
    padding: 5px;
    text-align: center;
}
.seofooter .social-icon {
    text-align: center;
}
/* -----------select multiselect------------- */
.rmsc .dropdown-container {
    height: 38px;
    border-radius: 0px!important;
    border:1px solid #dee2e6!important;
}
.rmsc .dropdown-container:focus-within {
    box-shadow: none!important;
    border-color: #E46422!important;
}
/* ----------------- */
@media screen and (max-width: 1399px) {
.banner-content {
    padding: 25px 15px 25px 0px;
    min-height: 273px;
    width: 85%;
}
}
@media screen and (max-width: 991px) {
    .Aboutus {
        padding: 50px 0px 50px 0px;
    }
    .seoheader{
            position: absolute;
            padding: 33px 0px;
            width: 100%;
            text-align: center;
        }
        .seoheader .emailContact{
            margin-top: 20px;
        }
    .banner-content {
        padding: 25px 15px 25px 0px;
        /* background: #07070738; */
        min-height: 273px;
        margin-top: 60px;
        width: 100%;
        /* border-left: 5px solid #ffffff; */
    }
    .about-content {
        padding-right: 0px;
        margin-bottom: 20px;
        /* text-align: justify; */
    }
    .propertyImage {
        position: relative;
        margin-top: 40px;
    }
    }
    @media screen and (max-width: 425px) {
        .seoheader {
            position: absolute;
            padding: 30px 0px 20px 0px;
            width: 100%;
            text-align: center;
        }
        .mbl-center {
            text-align: center;
        }
    .propertyImage {
        position: relative;
        margin-top: 80px;
    }
    .propertyImage .img3 {
        background: rgba(242,246,247,1);
        border-radius: 6px;
        padding: 8px;
        top: 171px;
        position: absolute;
        right: 24px;
        width: 35%;
        z-index: 1;
    }}
    @media screen and (max-width: 375px) {
        .emailContact ul li {
            display: inline-block;
            text-align: left;
            margin-bottom: 10px;
            margin-right: 15px;
        }
        .seopage .banner-area {
            min-height: 580px;
            padding: 100px 0px 80px 0px;
            background-size: 100% 100%;
            text-shadow: 3px 0px -1px #ffffff;
        }

    }
    @media screen and (max-width: 320px) {
    .propertyImage .img3 {
        background: rgba(242,246,247,1);
        border-radius: 6px;
        padding: 8px;
        top: 128px;
        position: absolute;
        right: 24px;
        width: 35%;
        z-index: 1;
    }}